import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { RisultatoDTO } from '../models';
import { environment } from '../../../environments/environment';
import { RecuperaPassword } from '../models/recupera-password';

const RP : string = '/application/csh/cishoppo/service/1.0/recuperaPassword';

@Injectable({
  providedIn: 'root'
})
export class PasswordService {

  private apiUrl: string;

  constructor(
    public httpClient: HttpClient
  ) { 
    this.apiUrl = environment.apiBaseUrl;
  }

  recuperaPassword(recuperaPassword: RecuperaPassword): Observable<RisultatoDTO<string>> {
    return this.httpClient.post<RisultatoDTO<string>>(`${this.apiUrl}${RP}`, recuperaPassword);
  }

}
