import { Injectable } from '@angular/core';
import { Router, NavigationStart } from '@angular/router';

 /** A router wrapper, adding extra functions. */
@Injectable()
export class RouterExtService {

  private previousUrl: string = undefined;
  private currentUrl: string = undefined;

  constructor(private router: Router) {

    this.currentUrl = this.router.url;
    router.events.subscribe(event => {
      if (event instanceof NavigationStart) {
        this.previousUrl = this.currentUrl;
        this.currentUrl = event.url;
      }
    });
  }

  public getPreviousUrl() {
    return this.previousUrl;
  }
  
  checkIfComingFromManageProducts(): boolean {
    let patternRoute = /^\/prodotto\/ricerca\/[\s\S]*$/
    if (this.getPreviousUrl().search(patternRoute) == -1) {
      return false;
    } else {
      return true;
    }
  }

  checkIfComingFromDetailShop(): boolean {
    let patternRoute = /^\/negozio\/dettaglio\/[\s\S]*$/
    if (this.getPreviousUrl().search(patternRoute) == -1) {
      return false;
    } else {
      return true;
    }
  }
}
