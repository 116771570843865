import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-timeout-modal',
  templateUrl: './timeout-modal.component.html',
  styleUrls: ['./timeout-modal.component.scss']
})
export class TimeoutModalComponent implements OnInit {

  @Input()
  countdown: number;

  constructor() {}

  ngOnInit() { }

  cancel() { }

}
