import { required } from '@rxweb/reactive-form-validators';

export class LoginFormModel {

    @required()
    t_email: string;

    @required()
    t_password: string;

    constructor() {
        this.t_email = null;
        this.t_password = null;
    }
}