import { Component } from '@angular/core';
import { navItems } from '../../../_nav';
import { environment } from '../../../../environments/environment';
import { ToastService } from '../../services';
import { Router } from '@angular/router';
import { INavData } from '@coreui/angular';
import { AuthService } from '../../services/auth/auth.service';
import { ManualeService } from '../../../modules/manuale/services/manuale.service';
import { MessageService } from '../../services/message/message.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './default-layout.component.html'
})
export class DefaultLayoutComponent {
  public sidebarMinimized = false;
  public navItems: Array<INavData>;
  //public navItems = navItems;
  public projectYear: string;
  public footerLink: string;
  isNegoziante: boolean;

  constructor(
    public authService: AuthService,
    private router: Router,
    private toastService: ToastService,
    private manualeService: ManualeService,
    private messageService: MessageService) {

    this.projectYear = new Date().getFullYear() + '';
    this.footerLink = environment.footerLink;

    this.authService.navItems$.subscribe({
      next: (items) => {
        if (items && items.length <= 1) {
          this.toastService.popErrorToast('Non sei autorizzato');
          this.authService.logout();
          return;
        }
        this.navItems = items;
      },
      error: () => {
        this.toastService.popErrorToast('Non sei autorizzato');
        this.authService.logout();
      }
    });
    this.setUtility();
  }

  toggleMinimize(e) {
    this.sidebarMinimized = e;
  }

  ngOnDestroy(): void {
    //this.changes.disconnect();
  }

  logout() {
    this.authService.logout();
  }

  setUtility() {
    if (this.getRuolo() === 'NEGOZIANTE') {
      this.isNegoziante = true;
    }
    else {
      this.isNegoziante = false;
    }
  }
  downloadManuale() {
    this.manualeService.getInfoManuale().subscribe(
      (result) => {
        if (result.code >= 200 && result.code < 300) {
          if (result.success) {
            const manuale = result.data.filter((el) => {
              return el.t_ruolo === this.getRuolo();
            });
            if (manuale[0] === undefined) {
              this.toastService.popErrorToast('Nessun manuale presente');
            }
            else {
              const num = manuale[0].size / 1000;
              const peso = Math.round((num + Number.EPSILON) * 100) / 100;
              this.messageService.confirm(`Sei sicuro di voler scaricare il file ${manuale[0].t_nome_file} dal peso di ${peso} Kb`)
                .subscribe(res => {
                  if (res && res.key === 'si') {
                    this.scaricaManuale(manuale[0].t_nome_file);
                  }
                });

            }

          } else {
            this.toastService.popErrorToast(result.descrizione);
          }
        } else {
          this.toastService.popErrorToast('Servizio non disponibile');
        }
      }
    );
  }
  scaricaManuale(filename: string) {
    this.manualeService.scaricaManuale(this.getRuolo()).subscribe(
      (result) => {
        if (result) {
          this.downloadFile(result, filename);
        }
        else {
          this.toastService.popErrorToast('Impossibile scaricare il file');
        }

      }
    );
  }

  downloadFile(file: Blob, filename: string) {
    const url = window.URL.createObjectURL(file);
    const a = document.createElement('a');
    document.body.appendChild(a);
    a.setAttribute('style', 'display: none');
    a.href = url;
    a.download = filename;
    a.click();
    window.URL.revokeObjectURL(url);
    a.remove();
  }

  getRuolo() {
    return this.authService.getDettaglioUtente().profilazione.ruolo.t_nome;
  }
}
