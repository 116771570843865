import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AppComponent } from './app.component';

// Import routing module
import { AppRoutingModule } from './app.routing';

// Import 3rd party components
import { LocaleDropdownComponent, ConfirmDialogComponent, TimeoutModalComponent, CroppingComponent, CarouselCustomComponent } from './core/components';
import { ToasterModule } from 'angular2-toaster';
import { JwtModule } from '@auth0/angular-jwt';
import { NgxUiLoaderModule, NgxUiLoaderRouterModule, NgxUiLoaderHttpModule } from 'ngx-ui-loader';
import { ngxUiLoaderConfig, ngxUiLoaderHttpConfig } from '../app/core/utility/configs';
import { registerLocaleData } from '@angular/common';
import localeIt from '@angular/common/locales/it';
import { CoreModule } from './core/core.module';
import { jwtTokenGetter, AuthService } from './core/services/auth/auth.service';
import { ImageCropperModule } from 'ngx-image-cropper';

registerLocaleData(localeIt, 'it');

@NgModule({
  exports: [ConfirmDialogComponent],
  imports: [
    BrowserModule,
    CommonModule,
    CoreModule,
    AppRoutingModule,
    NgxUiLoaderModule.forRoot(ngxUiLoaderConfig),
    NgxUiLoaderRouterModule,
    NgxUiLoaderHttpModule.forRoot(ngxUiLoaderHttpConfig),
    ToasterModule.forRoot(),
    JwtModule.forRoot({
      config: {
        tokenGetter: jwtTokenGetter,
        blacklistedRoutes: [
          "/prophet/authentication/ammit/oauth2/1.0/generateToken",
        ],
      }
    }),
    ImageCropperModule
  ],
  declarations: [
    AppComponent,
    ConfirmDialogComponent,
    TimeoutModalComponent,
    LocaleDropdownComponent,
    CroppingComponent,
    CarouselCustomComponent
  ],
  providers: [
    AppComponent,
    AuthService
  ],
  bootstrap: [AppComponent],
  entryComponents: [
    ConfirmDialogComponent,
    TimeoutModalComponent,
    CroppingComponent
  ]
})
export class AppModule { }
