import { Injectable } from '@angular/core';
import { FormGroup, ValidationErrors, } from '@angular/forms';
import * as CryptoJS from 'crypto-js';
import { CacheService } from 'ng2-cache';
import { City, Categoria, EsercenteTemp } from '../../../modules/negozio/models';
import { CategoriaProdotto } from '../../../modules/prodotto/models';
import { Utenza } from '../../../modules/negozio/models';
import { RuoloUtenza } from '../../../modules/utenza/models';
import { NavigationStart, Router } from '@angular/router';
import { filter } from 'rxjs/operators';
import { NOTALLOWED, PATH } from '../../utility/global-constant';
import { Profilazione } from '../../models';

const CACHE = 'CACHE';
const ENCRYPT_PARAMETERS = false;
const URI_ENCODE_PARAMETERS = false;
const phoneUtil = require('google-libphonenumber').PhoneNumberUtil.getInstance();

@Injectable({
  providedIn: 'root'
})
export class GlobalService {
  all_: string;
  mm: number;
  paramsSecret: string;
  cacheDate: Date;
  public readonly debug = true;

  constructor(
    protected _cacheService: CacheService,
    private router: Router,
  ) {
    this.all_ = '. '.repeat(1000);
    this.mm = 72 / 25.6;
    this.paramsSecret = '6QtxgFdnb9bBGm@7[s<A8h]X+%$B%FY&';
    this.cacheDate = new Date(0);
  }

  public getCache(objectName: string, key: string, defVal?: any): any {
    this.clearCacheIfNeeded();
    const cache = this._cacheService.get(CACHE);
    return cache && cache[objectName] && cache[objectName][key] || defVal;
  }

  public setCache(objectName: string, key: string, value: any) {
    this.clearCacheIfNeeded();
    let cache = this._cacheService.get(CACHE);
    if (!cache) {
      cache = {};
    }
    let objCache = cache[objectName];
    if (!objCache) {
      objCache = {};
      cache[objectName] = objCache;
    }
    objCache[key] = value;
    this._cacheService.set(CACHE, cache);
  }

  private clearCacheIfNeeded() {
    const now = new Date();
    now.setHours(0, 0, 0, 0);
    const cache = this.cacheDate;
    cache.setHours(0, 0, 0, 0);
    if (now > this.cacheDate) {
      this.cacheDate = now;
      this._cacheService.remove(CACHE);
    }
  }

  removeRouteCache(object_name: string, key: string, prev_path: string) {
    this.router.events.pipe(filter((event) => {
      return (event instanceof NavigationStart);
    })).subscribe((event: NavigationStart) => {
      if (event.navigationTrigger === 'imperative') {
        if (!event.url.includes(prev_path)) {
          this.setCache(object_name, key, null);
        }
      }
    });
  }

  public decodeParams(sparams: string): any {
    if (sparams) {
      let params;
      if (ENCRYPT_PARAMETERS) {
        params = JSON.parse(CryptoJS.AES.decrypt(sparams, this.paramsSecret).toString(CryptoJS.enc.Utf8));
      } else if (URI_ENCODE_PARAMETERS) {
        params = JSON.parse(decodeURI(sparams));
      } else {
        params = JSON.parse(atob(sparams));
      }
      return params;
    }
    return {};
  }

  public encodeParams(params: any): string {
    this.removeEmpty(params);
    params[' '] = new Date().getTime();
    if (ENCRYPT_PARAMETERS) {
      return CryptoJS.AES.encrypt(JSON.stringify(params), this.paramsSecret).toString();
    }
    if (URI_ENCODE_PARAMETERS) {
      return encodeURI(JSON.stringify(params));
    }
    return btoa(JSON.stringify(params));
  }

  removeEmpty = (obj) => {
    Object.keys(obj).forEach(key => {
      if (obj[key] && typeof obj[key] === 'object') {
        this.removeEmpty(obj[key]);
      } else if (obj[key] == null) {
        delete obj[key];
      } else if ((obj[key] instanceof Array) && (obj[key].length === 0)) {
        delete obj[key];
      }
    });
  }

  validateForm(form: FormGroup): boolean {
    if (!form.valid) {
      Object.keys(form.controls).forEach(field => {
        const control = form.get(field);
        control.markAsTouched({ onlySelf: true });
      });
      if (this.debug) {
        this.logFormErrors(form);
      }
    }
    return form.valid;
  }

  validateForms(form: FormGroup[]): boolean {
    let valid = true;
    if (form.length < 1) {
      return;
    }
    for (let i = 0; i < form.length; i++) {
      if (form[i]) {
        Object.keys(form[i].controls).forEach((field) => {
          if (!form[i].valid) {
            valid = false;
          }
          const control = form[i].get(field);
          control.markAsTouched({ onlySelf: true });
        });
      }
    }
    return valid;
  }

  logFormErrors(form: FormGroup) {
    if (!form.valid) {
      Object.keys(form.controls).forEach(key => {
        const controlErrors: ValidationErrors = form.get(key).errors;
        if (controlErrors) {
          Object.keys(controlErrors).forEach(keyError => {
            //console.log('Key control: ' + key + ', keyError: ' + keyError + ', err value: ', controlErrors[keyError]);
          });
        }
      });
    }
  }

  getEsercenteId(id_esercente: number, lista_esercente: Array<EsercenteTemp>): string {
    let nome;
    for (let esercente of lista_esercente) {
      if (esercente.id === id_esercente) {
        nome = esercente.nome;
      }
    }
    return nome;
  }

  getCityById(id_city: number, lista_city: Array<City>): string {
    let nome;
    for (let city of lista_city) {
      if (city.n_id === id_city) {
        nome = city.t_nome;
      }
    }
    return nome;
  }

  getCategoriaById(id_categoria: number, lista_categoria: Array<Categoria>): string {
    let nome;
    for (let categoria of lista_categoria) {
      if (categoria.n_id === id_categoria) {
        nome = categoria.t_tipo;
      }
    }
    return nome;
  }

  getCategoriaByNome(nome_categoria: string, lista_categorie: Array<CategoriaProdotto>): number {
    for (let categoria of lista_categorie) {
      if (categoria.t_nome_categoria === nome_categoria) {
        return categoria.n_id_categoria;
      }
    }
  }

  getRuoloByID(n_id: number, lista_ruolo: Array<RuoloUtenza>): string {
    let nome;
    for (let ruolo of lista_ruolo) {
      if (ruolo.n_id_ruolo === n_id) {
        nome = ruolo.t_nome_ruolo;
      }
    }
    return nome;
  }

  getIDRuoloByNome(t_nome: string, lista_ruolo: Array<RuoloUtenza>): number {
    let id_ruolo;
    for (let ruolo of lista_ruolo) {
      if (ruolo.t_nome_ruolo === t_nome) {
        id_ruolo = ruolo.n_id_ruolo;
      }
    }
    return id_ruolo;
  }

  //Esercente-Utenza
  getNomeCognomeById(n_id: string, lista_utenza: Array<Utenza>): string {
    for (let utenza of lista_utenza) {
      if (utenza.n_id === n_id) {
        return utenza.claimMap.Nome + ' ' + utenza.claimMap.Cognome;
      }
    }
  }

  //negozio - utenza
  getPrefissoByNumber(t_telefono_mobile: string) {
    const results = phoneUtil.parseAndKeepRawInput(t_telefono_mobile);
    if (phoneUtil.isValidNumber(results)) {
      let phone_length = results.getNationalNumber().toString().length;
      let size = t_telefono_mobile.length;
      return t_telefono_mobile.substring(0, size - phone_length);
    }
  }

  getOriginalNumberByNumber(t_telefono_mobile: string): string {
    const results = phoneUtil.parseAndKeepRawInput(t_telefono_mobile);
    if (phoneUtil.isValidNumber(results)) {
      return results.getNationalNumber().toString();
    }
  }

  checkPhoneNumber(t_prefisso: string, t_telefono_mobile: string) {
    try {
      const regex = new RegExp(/^\d+$/);
      if (!regex.test(t_telefono_mobile)) {
        return false;
      }
      const complete_phone_number = t_prefisso + t_telefono_mobile;
      const results = phoneUtil.parseAndKeepRawInput(complete_phone_number);
      return phoneUtil.isValidNumber(results);
    } catch (e) { return false }
  }

  checkWordsNotAllowed(jsonForm: string): boolean {
    let trovato: boolean = false;
    NOTALLOWED.wordsNotAllowed.forEach(words => {
      if (jsonForm.toLocaleUpperCase().includes(words.toLocaleUpperCase())) {
        trovato = true;
      }
    });
    return trovato;
  }

  getUrlIntoInfo(info: string): string {
    let url;
    if (info.split('|').length === 2) {
      url = info.split('|')[1];
    } else {
      url = '';
    }
    return url;
  }

  checkIsNavigationAllowed(path: string): boolean {
    let result: boolean = false;
    if (this.checkPathAllowed(path)) {
      result = true;
    } else {
      let profilazione: Profilazione = JSON.parse(sessionStorage.getItem('profilazione'));
      if (profilazione) {
        for (let funzione of profilazione.ruolo.funzioni) {
          for (let funzionalita of funzione.funzionalita) {
            if (this.getUrlIntoInfo(funzionalita.t_info_aggiuntive) === path) {
              result = true;
            }
          }
        }
      }
    }
    return result;
  }

  checkPathAllowed(path: string): boolean {
    let result: boolean = false;
    for (let percorso of PATH.path_allowed) {
      if (path.includes(percorso)) {
        result = true;
      }
    }
    return result;
  }

  getLocaleDateString(data: Date) {
    const formattedData = data.toISOString().split('T')[0];
    const formattedHour = data.toLocaleTimeString();
    return formattedData + 'T' + formattedHour + '.000Z';
  }

  convertBase64ToBlob(base64: string): Blob {
    let pos = base64.indexOf(';base64,');
    let type = base64.substring(5, pos);
    let b64 = base64.substr(pos + 8);
    // decode base64
    let imageContent = atob(b64);
    // create an ArrayBuffer and a view (as unsigned 8-bit)
    let buffer = new ArrayBuffer(imageContent.length);
    let view = new Uint8Array(buffer);
    // fill the view, using the decoded base64
    for (let n = 0; n < imageContent.length; n++) {
      view[n] = imageContent.charCodeAt(n);
    }
    // convert ArrayBuffer to Blob
    let blob = new Blob([buffer], { type: type });
    return blob;
  }

}
