import { Component } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { LoginFormModel } from './login.form.model';
import { RxFormBuilder } from '@rxweb/reactive-form-validators';
import { ToastService, GlobalService } from '../../services';
import { Login } from '../../models';
import { sha512 } from 'js-sha512';
import { Router } from '@angular/router';
import { environment } from '../../../../environments/environment';
import { AuthService } from '../../services/auth/auth.service';
import { APPLICAZIONE } from '../../utility/global-constant';

@Component({
  selector: 'app-dashboard',
  templateUrl: 'login.component.html'
})
export class LoginComponent {

  form: FormGroup;
  login_request: Login;
  password_type: string = "password";
  password_icon: string = "fa fa-eye-slash";

  constructor(
    private formBuilder: RxFormBuilder,
    private globalService: GlobalService,
    private authService: AuthService,
    private router: Router,
    private toastService: ToastService) {
    this.creaForm(new LoginFormModel);
  }

  creaForm(lfm: LoginFormModel) {
    this.form = this.formBuilder.formGroup(lfm);
  }

  set t_email(t_email: string) {
    this.form.get('t_email').setValue(t_email);
  }

  get t_email() {
    return this.form.get('t_email').value;
  }

  set t_password(t_password: string) {
    this.form.get('t_password').setValue(t_password);
  }

  get t_password() {
    return this.form.get('t_password').value;
  }

  login() {
    if (this.globalService.validateForm(this.form)) {
      let email = this.t_email.trim();
      this.t_email = email;
      this.login_request = {
        username: this.t_email,
        password: sha512(this.t_password),
        type: APPLICAZIONE.type
      };
      //Prima Generate Token
      this.authService.generateToken().subscribe((result) => {
        if (result.code >= 200 && result.code < 300 && result.success) {
          this.authService.storeToken(result.data.access_token);
          this.authService.storeExpiresToken(result.data.expires_at);
          //CHIAMO LOGIN
          this.authService.login(this.login_request).subscribe(
            (result) => {
              if (result.code >= 200 && result.code < 300) {
                if (result.success) {
                  if(result.data.profilazione.ruolo.funzioni.length > 0) {
                    this.toastService.popSuccessToast('Login effettuato');
                    this.router.navigate(['/negozio/ricerca']);
                  } else {
                    this.toastService.popWarnigToast('Utenza presente ma non attiva');
                  }
                } else {
                  this.toastService.popErrorToast(result.descrizione);
                  this.router.navigate([environment.loginPage]);
                }
              } else {
                this.toastService.popErrorToast('Servizio non disponibile');
                this.router.navigate([environment.loginPage]);
              }
            });
        }
    
      });
    }
  }

  passwordDimenticataPage() {
    this.router.navigate(['/password-dimenticata']);
  }

  showHidePassword() {
    if(this.password_type === 'password') {
      this.password_type = 'text';
      this.password_icon = 'fa fa-eye';
    } else {
      this.password_type = 'password';
      this.password_icon = 'fa fa-eye-slash'
    }
  }

}
