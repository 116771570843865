import { Injectable } from '@angular/core';
import { CanLoad, CanActivate, Route, UrlSegment, Router, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { ToastService, GlobalService } from '../services';
import { environment } from '../../../environments/environment';
import { AuthService } from '../services/auth/auth.service';

/**
 * @description service che definisce se uno specifico modulo/percorso è navigabile dall'utente
 * (se esso è in possesso del token di autenticazione), altrimenti effettua un redirect alla pagina di login.
 * Implementa l'interfaccia CanLoad che può impedire il lazy loading del modulo in cui si vuole navigare.
 * Implenta l'interfaccia CanActivate che può impedire la navgazione in un path di un modulo già caricato.
 *
 * @example
 *  { path: 'nome_percorso', canLoad: [AuthGuard], loadChildren: './path_modulo#nome_modulo' }
 *  { path: 'nome_percorso', canActivate: [AuthGuard], path: './path_componente' }
 */
@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanLoad, CanActivate {

  constructor(private authService: AuthService,
              private toastService: ToastService,
              private globalService: GlobalService,
              private router: Router) { }

  /**
   * Il metodo controlla che l'utente sia in possesso di un token valido, al fine di caricare il modulo relativo
   * al percorso che sta navigando.
   *
   * @param { Route } route oggetto che rappresenta la specifica route in cui l'utente vuole navigare.
   *
   * @param { UrlSegment[] } segments oggetto che rappresenta le porzioni di url in cui l'utente vuole navigare, includendo anche i
   * parametri di navigazione.
   *
   * @returns {  Observable<boolean> | Promise<boolean> | boolean } se true l'utente può accedere al modulo, se false gli è impedita
   * la navigazione e il modulo non è caricato
   */
  canLoad(route: Route, segments: UrlSegment[]): Observable<boolean> | Promise<boolean> | boolean {
    if (!this.authService.isAuthenticated) {
      this.router.navigate([environment.loginPage]);
      this.toastService.popInfoToast('Effettua il login per poter utilizzare i servizi di Cishoppo');
      return false;
    }
    return true;
  }

  /**
   * Il metodo controlla che l'utente sia in possesso di un token valido, al fine di permettere o meno la navigazione.
   * Il metodo è chiamato solo nel caso in cui il modulo è stato caricato in memoria.
   * Ad esempio se dopo il login è caricato il modulo 'negozio', esso rimarrà in memoria fin quando l'utente non chiuderà la pagina web
   * o il browser, per cui il metodo canLoad non verrà chiamato alla navigazione verso il modulo 'negozio'
   *
   * @param { ActivatedRouteSnapshot } route oggetto che rappresenta la specifica route in cui l'utente vuole navigare (la componente
   * associata è gia caricata), fornisce l'alberatura dei percorsi relativi allo specifico path.
   *
   * @param { RouterStateSnapshot } state oggetto che rappresenta lo stato del router al momento della navigazione verso un percorso.
   *
   * @returns {
   *  Observable<boolean|UrlTree>|Promise<boolean|UrlTree>|boolean|UrlTree
   * } se true l'utente può accedere al percorso, se false gli è impedita la navigazione.
   */
  canActivate(
    route: ActivatedRouteSnapshot, state: RouterStateSnapshot
  ): Observable<boolean|UrlTree>|Promise<boolean|UrlTree>|boolean|UrlTree {
    if(!this.globalService.checkIsNavigationAllowed(state.url)) {
      return false;
    }
    if (!this.authService.isAuthenticated) {
      this.router.navigate([environment.loginPage]);
      this.toastService.popInfoToast('Effettua il login per poter utilizzare i servizi di Cishoppo');
      return false;
    }
    return true;
  }

}
