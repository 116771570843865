import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd, ActivationEnd, NavigationStart, RouterEvent, NavigationCancel, NavigationError } from '@angular/router';
import { ToasterConfig } from 'angular2-toaster';
import { NgZone } from '@angular/core';
import { HttpCancelService } from './core/services';
import { SpinnerService } from './core/services/spinner/spinner.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';
@Component({
  // tslint:disable-next-line
  selector: 'body',
  templateUrl: './app.component.html'
})
export class AppComponent implements OnInit {
  constructor(
    private router: Router,
    private httpCancelService: HttpCancelService,
    private spinnerService: SpinnerService,
    private ngZone: NgZone,
    private ngxService: NgxUiLoaderService) {
      router.events.subscribe((event: RouterEvent) => {
        this.navigationInterceptor(event); // ogni volta che naviga chiama lo spinner
      });
  }

  public toasterConfig: ToasterConfig =
    new ToasterConfig({
      showCloseButton: true,
      tapToDismiss: true,
      limit: 1
    });

  cancel() {
    this.httpCancelService.cancelPendingRequests();
  }

  ngOnInit() {
    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      if (evt instanceof ActivationEnd) {
        this.httpCancelService.cancelPendingRequests();
      }
      window.scrollTo(0, 0);
    });
 
  }

  navigationInterceptor(event: RouterEvent): void {
    if (event instanceof NavigationStart) {
      this.ngZone.runOutsideAngular(() => this.spinnerService.increaseLoading());
    }
    if (event instanceof NavigationEnd) {
      this.ngZone.runOutsideAngular(() => this.spinnerService.decreaseLoading());
    }
    if (event instanceof NavigationCancel) {
      this.ngZone.runOutsideAngular(() => this.spinnerService.decreaseLoading());
    }
    if (event instanceof NavigationError) {
      this.ngZone.runOutsideAngular(() => this.spinnerService.decreaseLoading());
    }
  }
}
