import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { DefaultLayoutComponent, P404Component, P500Component, LoginComponent, PasswordDimenticataComponent } from '../app/core/components';
import { AuthGuard } from './core/guards/auth.guard';

export const routes: Routes = [
  {
    path: '',
    redirectTo: 'negozio',
    pathMatch: 'full',
  },
  {
    path: '404',
    component: P404Component,
    data: {
      title: 'Page 404'
    }
  },
  {
    path: '500',
    component: P500Component,
    data: {
      title: 'Page 500'
    }
  },
  {
    path: 'login',
    component: LoginComponent,
    data: {
      title: 'Login Page'
    }
  },
  {
    path: 'password-dimenticata',
    component: PasswordDimenticataComponent,
    data: {
      title: 'Password Dimenticata Page'
    }
  },
  {
    path: '',
    component: DefaultLayoutComponent,
    children: [
      {
        path: 'utenza',
        canLoad: [AuthGuard],
        canActivate: [AuthGuard],
        loadChildren: () => import('../app/modules/utenza/utenza.module').then(m => m.UtenzaModule)
      },
      {
        path: 'negozio',
        canLoad: [AuthGuard],
        canActivate: [AuthGuard],
        loadChildren: () => import('../app/modules/negozio/negozio.module').then(m => m.NegozioModule)
      },
      {
        path: 'prodotto',
        canLoad: [AuthGuard],
        canActivate: [AuthGuard],
        loadChildren: () => import('../app/modules/prodotto/prodotto.module').then(m => m.ProdottoModule)
      },
      {
        path: 'citta',
        canLoad: [AuthGuard],
        canActivate: [AuthGuard],
        loadChildren: () => import('../app/modules/citta/citta.module').then(m => m.CittaModule)
      },
      {
        path: 'categoriacommerciale',
        canLoad: [AuthGuard],
        canActivate: [AuthGuard],
        loadChildren: () => import('../app/modules/categoria-commerciale/categoria-commerciale.module').then(m => m.CategoriaCommercialeModule)
      },
      {
        path: 'categoriaprodotto',
        canLoad: [AuthGuard],
        canActivate: [AuthGuard],
        loadChildren: () => import('../app/modules/categoria-prodotto/categoria-prodotto.module').then(m => m.CategoriaProdottoModule)
      },
      {
        path: 'unitamisura',
        canLoad: [AuthGuard],
        canActivate: [AuthGuard],
        loadChildren: () => import('../app/modules/unita-misura/unita-misura.module').then(m => m.UnitaMisuraModule)
      },
      {
        path: 'entepatrocinante',
        canLoad: [AuthGuard],
        canActivate: [AuthGuard],
        loadChildren: () => import('../app/modules/ente-patrocinante/ente-patrocinante.module').then(m => m.EntePatrocinanteModule)
      },
      {
        path: 'faq',
        canLoad: [AuthGuard],
        canActivate: [AuthGuard],
        loadChildren: () => import('../app/modules/faq/faq.module').then(m => m.FaqModule)
      },
      {
        path: 'ordine',
        canLoad: [AuthGuard],
        canActivate: [AuthGuard],
        loadChildren: () => import('../app/modules/ordine/ordine.module').then(m => m.OrdineModule)
      },
      {
        path: 'taglia',
        canLoad: [AuthGuard],
        canActivate: [AuthGuard],
        loadChildren: () => import('../app/modules/taglia/taglia.module').then(m => m.TagliaModule)
      },
      {
        path: 'catalogo',
        canLoad: [AuthGuard],
        canActivate: [AuthGuard],
        loadChildren: () => import('../app/modules/catalogo/catalogo.module').then(m => m.CatalogoModule)
      },
      {
        path: 'manuale',
        canLoad: [AuthGuard],
        canActivate: [AuthGuard],
        loadChildren: () => import('../app/modules/manuale/manuale.module').then(m => m.ManualeModule)
      }
    ]
  },
  { path: '**', component: P404Component }
];

@NgModule({
  imports: [ RouterModule.forRoot(routes) ],
  exports: [ RouterModule ]
})
export class AppRoutingModule {}
