import { Component } from '@angular/core';
import { GlobalService, ToastService, PasswordService } from '../../services';
import { Router } from '@angular/router';
import { FormGroup } from '@angular/forms';
import { RecuperaPassword } from '../../models/recupera-password';
import { PasswordDimenticataFormModel } from './password-dimenticata-form.model';
import { RxFormBuilder } from '@rxweb/reactive-form-validators';
import { sha512 } from 'js-sha512';
import { environment } from '../../../../environments/environment';
import { AuthService } from '../../services/auth/auth.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './password-dimenticata.component.html',
  styleUrls: ['./password-dimenticata.component.css']
})
export class PasswordDimenticataComponent {

  form: FormGroup;
  recupera_password_request: RecuperaPassword;
  password_generata: string;

  constructor(
    private formBuilder: RxFormBuilder,
    private globalService: GlobalService,
    private passwordService: PasswordService,
    private router: Router,
    private toastService: ToastService,
    private authService: AuthService) {
    this.creaForm(new PasswordDimenticataFormModel);
  }

  creaForm(pdfm: PasswordDimenticataFormModel) {
    this.form = this.formBuilder.formGroup(pdfm);
  }

  set t_email(t_email: string) {
    this.form.get('t_email').setValue(t_email);
  }

  get t_email() {
    return this.form.get('t_email').value;
  }

  inviaPassword() {
    if (this.globalService.validateForm(this.form)) {
      let email = this.t_email.trim();
      this.t_email = email;
      //Prima Generate Token
      this.authService.generateToken().subscribe((result) => {
        this.authService.storeToken(result.data.access_token);
        this.authService.storeExpiresToken(result.data.expires_at);
        this.passwordService.recuperaPassword(this.costruisciRequest()).subscribe(
          (result) => {
            if (result.code >= 200 && result.code < 300) {
              if (result.success) {
                this.toastService.popSuccessToast(result.descrizione);
                this.router.navigate([environment.loginPage]);
              } else {
                this.toastService.popErrorToast(result.descrizione);
              }
            } else {
              this.toastService.popErrorToast(result.descrizione);
            }
          }
        )
      });
    }
  }

  costruisciRequest(): RecuperaPassword {
    var generator = require('generate-password-browser');
    this.password_generata = generator.generate({
      length: 8,
      numbers: true
    })
    return this.recupera_password_request = {
      username: this.t_email,
      password_blurred: sha512(this.password_generata),
      password: this.password_generata
    };
  }

}
