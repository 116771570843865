import { environment } from '../../../environments/environment';
import { HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { LoaderInterceptor } from '../interceptors/loader-interceptor';
import { HttpTokenInterceptor } from '../interceptors/http-token-interceptor';

// Factories
export function HttpLoaderFactory(http: HttpClient) {
    return new TranslateHttpLoader(http, environment.translationsPrefix, environment.translationsSuffix);
}

// Providers
export const httpInterceptorProviders = {
    provide: HTTP_INTERCEPTORS,
    useClass: HttpTokenInterceptor,
    multi: true
};

export const httpLoaderInterceptorProvider = {
    provide: HTTP_INTERCEPTORS,
    useClass: LoaderInterceptor,
    multi: true
};
