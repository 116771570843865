import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-carousel-custom',
  templateUrl: './carousel-custom.component.html',
  styleUrls: ['./carousel-custom.component.css']
})
export class CarouselCustomComponent implements OnInit {
  constructor() {
    
  }
  ngOnInit(): void {
    throw new Error('Method not implemented.');
  }




  
}