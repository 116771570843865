import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

export class Option {
  constructor(public key: string, public name: string) {}
}

@Component({
  selector: 'app-confirm-dialog',
  templateUrl: './confirm-dialog.component.html'
})
export class ConfirmDialogComponent implements OnInit {
  title: string;
  messages: string;
  options: Array<Option>;
  answer: Option;

  constructor(public bsModalRef: BsModalRef) { }

  respond(answer?: Option) {
    this.answer = answer ? answer : new Option(null, null);
    this.bsModalRef.hide();
  }

  ngOnInit() { }

}
