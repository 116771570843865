import { NgxUiLoaderConfig, SPINNER, NgxUiLoaderRouterConfig, NgxUiLoaderHttpConfig } from 'ngx-ui-loader';
import { NgSelectConfig } from '@ng-select/ng-select';
import { UiSwitchModuleConfig } from 'ngx-ui-switch/ui-switch/ui-switch.config';
import { TranslateModuleConfig, TranslateLoader } from '@ngx-translate/core';
import { HttpLoaderFactory } from './factories';
import { HttpClient } from '@angular/common/http';
import { OwlOptions } from 'ngx-owl-carousel-o';

// Loader
export const ngxUiLoaderConfig: NgxUiLoaderConfig = {
  fgsType: SPINNER.ballScaleMultiple,
  fgsSize: 60,
  pbThickness: 3,
  bgsOpacity: 1,
  bgsPosition: 'top-right',
  bgsSize: 60,
  bgsType: SPINNER.ballScaleMultiple,
  blur: 0,
  fgsPosition: 'top-right',
  fgsColor: '#660c0c',
  overlayBorderRadius: '0',
  hasProgressBar: true,
  pbColor: '#660c0c',
  overlayColor: 'rgba(40, 40, 40, 0.2)',
};

export const ngxUiLoaderRouterConfig: NgxUiLoaderRouterConfig = {
  //excludeRegexp: ['login']
};

export const ngxUiLoaderHttpConfig: NgxUiLoaderHttpConfig = {
  showForeground: true
};
//SLIDER SWITCH

export const uiSwitchModuleConfig: UiSwitchModuleConfig = {
  size: 'small',
  color: '#660c0c',
  switchColor: '#fffff',
  defaultBgColor: '#888',
  defaultBoColor: '#ced4da',
  checkedLabel: '',
  uncheckedLabel: ''
};

// Translate
export const translateModuleConfig: TranslateModuleConfig = {
  loader: {
    provide: TranslateLoader,
    useFactory: HttpLoaderFactory,
    deps: [HttpClient]
  },
  useDefaultLang: true
};

// Carosel
export const owlCaroselConfig: OwlOptions = {
  loop: false,
  mouseDrag: true,
  touchDrag: true,
  pullDrag: false,
  dots: true,
  center: false,
  navSpeed: 700,
  margin: 10,
  navText: ["<div class='btn btn-primary prev-slide fa fa-angle-left'></div>", "<div class='btn btn-primary next-slide fa fa-angle-right '></div>"],
  autoWidth: false,
  autoHeight: true,
  responsive: {
    0: {
      items: 1
    },
    800: {
      items: 2
    }
  },
  nav: true,

};


