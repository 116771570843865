import { Component, Input, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ImageCroppedEvent } from 'ngx-image-cropper';
import { Subject } from 'rxjs';
import { INFO_IMMAGINI_CATEGORIA_COMMERCIALE, INFO_IMMAGINI_ENTE_PATROCINANTE, INFO_IMMAGINI_NEGOZIO, INFO_IMMAGINI_PRODOTTO } from '../../utility/global-constant';

@Component({
  selector: 'app-cropping',
  templateUrl: './cropping.component.html',
  styleUrls: ['./cropping.component.css']
})
export class CroppingComponent implements OnInit {
  @Input() imageBase64: string;
  @Input() locate_image: string;
  imageChangedEvent: any = '';
  croppedImage: any = '';
  imageCropped$: Subject<string> = new Subject<string>();
  resize_height: number;
  resize_width: number;
  aspectRatio: number;

  constructor(
    private bsModalRef: BsModalRef) {
  }

  ngOnInit() {
    this.checkFromWhere();
  }

  fileChangeEvent(event: any): void {
    this.imageChangedEvent = event;
  }

  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.base64;
  }

  imageLoaded() {
    // show cropper
  }

  cropperReady() {
    // cropper ready
  }

  loadImageFailed() {
    // show message
  }

  annullaCropping() {
    this.imageCropped$.next('noimage');
    this.bsModalRef.hide();
  }

  confermaImage() {
    this.imageCropped$.next(this.croppedImage);
    this.bsModalRef.hide();
  }

  checkFromWhere() {
    switch (this.locate_image) {
      case 'NEGOZIO':
        this.aspectRatio = this.getDimensioniCropped(INFO_IMMAGINI_NEGOZIO.aspectRatio);
        this.resize_height = INFO_IMMAGINI_NEGOZIO.resizeToHeight;
        this.resize_width = INFO_IMMAGINI_NEGOZIO.resizeToWidth;
        break;
      case 'PRODOTTO':
        this.aspectRatio = this.getDimensioniCropped(INFO_IMMAGINI_PRODOTTO.aspectRatio);
        this.resize_height = INFO_IMMAGINI_PRODOTTO.resizeToHeight;
        this.resize_width = INFO_IMMAGINI_PRODOTTO.resizeToWidth;
        break;
      case 'CATEGORIA_COMMERCIALE':
        this.aspectRatio = this.getDimensioniCropped(INFO_IMMAGINI_CATEGORIA_COMMERCIALE.aspectRatio);
        this.resize_height = INFO_IMMAGINI_CATEGORIA_COMMERCIALE.resizeToHeight;
        this.resize_width = INFO_IMMAGINI_CATEGORIA_COMMERCIALE.resizeToWidth;
        break;
      case 'ENTE_PATROCINANTE':
        this.aspectRatio = this.getDimensioniCropped(INFO_IMMAGINI_ENTE_PATROCINANTE.aspectRatio);
        this.resize_height = INFO_IMMAGINI_ENTE_PATROCINANTE.resizeToHeight;
        this.resize_width = INFO_IMMAGINI_ENTE_PATROCINANTE.resizeToWidth;
        break;
    }
  }

  getDimensioniCropped(dim: string): number {
    let value: number;
    switch (dim) {
      case '22/10':
        value = 22 / 10;
        break;
      case '16/9':
        value = 16 / 9;
        break;
      case '4/3':
        value = 4 / 3;
        break;
      case '1/1':
        value = 1;
        break;
    }
    return value;
  }


}
