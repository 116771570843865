import { environment } from './../../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { RisultatoDTO } from '../../../core/models/RisultatoDTO';
import { Documento } from '../models/documento';
import { Manuale } from '../models/manuale';

export const IM = 'application/csh/cishoppo/gestionemanuali/1.0/inserisciManuale';
export const GIMBR = 'application/csh/cishoppo/gestionemanuali/1.0/getInfoManuali';
export const EM = 'application/csh/cishoppo/gestionemanuali/1.0/eliminaManuale';
export const SM = 'application/csh/cishoppo/gestionemanuali/1.0/scaricaManuale';

@Injectable({
  providedIn: 'root'
})
export class ManualeService {

  constructor(
    private httpClient: HttpClient) { }


  inserisciManuale(documento: Documento): Observable<RisultatoDTO<string>> {
    return this.httpClient.post<RisultatoDTO<string>>(`${environment.apiBaseUrl}${IM}`, documento);
  }


  getInfoManuale(): Observable<RisultatoDTO<Array<Manuale>>> {
    return this.httpClient.get<RisultatoDTO<Array<Manuale>>>(`${environment.apiBaseUrl}${GIMBR}`);
  }

  eliminaManuale(t_ruolo: string): Observable<RisultatoDTO<string>> {
    const parametri = '?t_ruolo=' + t_ruolo;
    return this.httpClient.delete<RisultatoDTO<string>>(`${environment.apiBaseUrl}${EM}${parametri}`);
  }

  scaricaManuale(t_ruolo: string): Observable<Blob> {
    const parametri = '?t_ruolo=' + t_ruolo;
    return this.httpClient.get(`${environment.apiBaseUrl}${SM}${parametri}`, { responseType: 'blob' });
  }


}
