import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, BehaviorSubject } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { RisultatoDTO, IUserLoginResponse, Login, Profilazione, Funzione, TokenResponse, DettaglioUtente } from '../../models';
import { CacheService } from 'ng2-cache';
import { environment } from '../../../../environments/environment';
import { IdleService } from '../idle/idle.service';
import { idle } from '../../../../environments/environment';
import { MessageService, SpinnerService } from '../../services';
import { INavData } from '@coreui/angular';

//PATH SERVIZI
export const LOGIN = 'application/csh/cishoppo/service/3.0/login';
export const GT: string = 'authentication/ammit/oauth2/1.0/generateToken';

//STORAGE
export const DU = 'dettaglio_utente';
export const PRO = 'profilazione';

export const ACCESSTOKENKEY = 'access_token';
export const EXPIRESTOKEN = 'expires_in';
export const LOGGEDUSERKEY = 'User';

export function jwtTokenGetter() {
  return sessionStorage.getItem(ACCESSTOKENKEY); // TOKEN_KEY
}

@Injectable()
export class AuthService {

  private navItems: BehaviorSubject<Profilazione>;
  private apiUrl: string;

  constructor(
    private messageService: MessageService,
    private httpClient: HttpClient,
    private router: Router,
    protected _cacheService: CacheService,
    private idleService: IdleService,
    private spinnerService: SpinnerService) {

    this.apiUrl = environment.apiBaseUrl;
    this.navItems = new BehaviorSubject<Profilazione>(this.getProfilazioneUtente());
  }

  logout(): void {
    this.clearUser();
  }

  login(login: Login): Observable<RisultatoDTO<DettaglioUtente>> {
    return this.httpClient
      .post<RisultatoDTO<DettaglioUtente>>(`${this.apiUrl}${LOGIN}`, login).pipe(
        map(result => {
          if (result && result.success && result.data) {
            if (result.data.profilazione.ruolo.funzioni.length > 0) {
              this.storeDettaglioUtente(result.data);
              this.storeProfilazioneUtente(result.data.profilazione);
              this.navItems.next(result.data.profilazione);
              const observables = this.idleService.start(environment.minuti_inattivita, idle.idletimepercentage, idle.keepaliveinterval);
              observables.onTimeoutWarning.subscribe((countdown) => this.idleService.showTimeoutModal(countdown));
              observables.onTimeout.subscribe(() => this.logout());
              observables.onPing.subscribe(() => this.idleService.hideTimeoutModal())
            } else {
              this.logout();
            }
          }
          return result;
        })
      )
    // .pipe(catchError(err => this.resultLoginError(err)));
  }

  generateToken(): Observable<RisultatoDTO<TokenResponse>> {
    const headers = new HttpHeaders({
      'Authorization': 'Basic ' + btoa(environment.applicationUsername + ':' + environment.applicationPassword)
    });
    return this.httpClient.get<RisultatoDTO<TokenResponse>>(`${environment.apiBaseUrl}${GT}`, { headers: headers });
  }

  refreshToken() {
    const headers = new HttpHeaders({
      'Authorization': 'Basic ' + btoa(environment.applicationUsername + ':' + environment.applicationPassword)
    });
    return this.httpClient.get<RisultatoDTO<TokenResponse>>(`${environment.apiBaseUrl}${GT}`, { headers: headers }).pipe(tap((result: RisultatoDTO<TokenResponse>) => {
      this.storeToken(result.data.access_token);
      this.storeExpiresToken(result.data.expires_at);
    }));
  }

  createMenu(value: Array<Funzione>): INavData[] {
    const nav: INavData[] = [{ title: true, name: environment.projectName }];
    if (!value) {
      return nav;
    }
    for (let i = 0; i < value.length; i++) {
      const obj: INavData = {};
      const children: INavData[] = [];
      obj.name = value[i].t_nome;
      obj.url = this.getUrl(value[i].t_info_aggiuntive);
      obj.icon = this.getIcon(value[i].t_info_aggiuntive);
      for (let j = 0; j < value[i].funzionalita.length; j++) {
        const childItem: INavData = {};
        childItem.name = value[i].funzionalita[j].t_nome;
        childItem.url = this.getUrl(value[i].funzionalita[j].t_info_aggiuntive);
        childItem.icon = this.getIcon(value[i].funzionalita[j].t_info_aggiuntive);
        if (Object.keys(childItem).length !== 1) {
          children.push(childItem);
        }
      }
      obj.children = children;
      nav.push(obj);
    }
    return nav;
  }

  get navItems$(): Observable<INavData[]> {
    return this.navItems.asObservable().pipe(map((value) => {
      if (!value) {
        return;
      }
      return this.createMenu(value.ruolo.funzioni);
    }));
  }

  get isLogged(): boolean {
    const dettaglio_utente = this.getDettaglioUtente();
    return dettaglio_utente && this.isAuthenticated;
  }

  get isAuthenticated(): boolean {
    let token = this.getToken();
    console.log('Data scadenza(string) token: ' + this.getExpiresToken())
    return token !== undefined && token !== null;
  }

  getUrl(info: string): string {
    let url;
    if (info.split('|').length === 2) {
      url = info.split('|')[1];
    } else {
      url = '';
    }
    return url;
  }

  getIcon(info: string): string {
    let icon;
    if (info.split('|').length === 2) {
      icon = info.split('|')[0];
    } else {
      // icon = this.icona_dafault;
    }
    return icon;
  }

  checkExpiresDateToken(token: string): boolean {
    let data_corrente = new Date();
    let expires_date = new Date(this.getExpiresToken());
    let esito: boolean = false;
    if ((expires_date.getTime()) < data_corrente.getTime()) {
      esito = true;
    } else {
      esito = false;
    }
    return esito;
  }

  clearUser(): void {
    this.messageService.closeAllModals();
    this._cacheService.removeAll();
    this.idleService.stop();
    this.deleteToken();
    this.deleteExpiresToken();
    this.deleteDettaglioUtente();
    this.deleteProfilazioneUtente();
    this.router.navigate([environment.logoutPage]);
  }

  storeToken(token: string): void {
    sessionStorage.setItem(ACCESSTOKENKEY, token);
  }

  storeExpiresToken(expiresIn: string) {
    sessionStorage.setItem(EXPIRESTOKEN, expiresIn);
  }

  storeDettaglioUtente(dettaglio_utente: DettaglioUtente) {
    sessionStorage.setItem(DU, JSON.stringify(dettaglio_utente));
  }

  storeProfilazioneUtente(profilazione: Profilazione) {
    sessionStorage.setItem(PRO, JSON.stringify(profilazione));
  }

  getToken() {
    return sessionStorage.getItem(ACCESSTOKENKEY);
  }

  getExpiresToken(): string {
    return sessionStorage.getItem(EXPIRESTOKEN);
  }

  getDettaglioUtente(): DettaglioUtente {
    return JSON.parse(sessionStorage.getItem(DU));
  }

  getProfilazioneUtente(): Profilazione {
    return JSON.parse(sessionStorage.getItem(PRO));
  }

  deleteUser(): void {
    sessionStorage.removeItem(LOGGEDUSERKEY);
  }

  deleteToken(): void {
    sessionStorage.removeItem(ACCESSTOKENKEY);
  }

  deleteExpiresToken(): void {
    sessionStorage.removeItem(EXPIRESTOKEN);
  }

  deleteDettaglioUtente(): void {
    sessionStorage.removeItem(DU);
  }

  deleteProfilazioneUtente(): void {
    sessionStorage.removeItem(PRO);
  }

  updateUser(userLoginResponse: IUserLoginResponse) {
    sessionStorage.setItem(LOGGEDUSERKEY, JSON.stringify(userLoginResponse));
  }

  getUserInfo(): IUserLoginResponse {
    return JSON.parse(sessionStorage.getItem(LOGGEDUSERKEY)) as IUserLoginResponse;
  }

}
