import { messages } from './form-constant';

export const FORM = {
  ricerca_reset_form: false,
  //SBZI -> search button zero inputs
  enable_SBZI: true
};

export const APPLICAZIONE = {
  type: 'W'
};

export const NOTALLOWED = {
  wordsNotAllowed: [
    'covid19', 'covid', 'covid-19', 'covid 19', 'c0vid', 'cov1d', 'c0v1d', 'pandemia', 'coronavirus', 'virus', 'corona-virus', 'corona virus', 'sars', 'sars cov', 'sars-cov'
  ],
  message: 'Il sistema ha riscontrato la presenza di parole non ammissibili'
};

export const PATH = {
  path_allowed: [
    'utenza/dettaglio', 'utenza/cambiapassword',
    'negozio/dettaglio', 'negozio/completaLight', 'negozio/configpay',
    'prodotto/ricerca', 'prodotto/nuovo', 'prodotto/dettaglio',
    'citta/dettaglio',
    'categoriacommerciale/dettaglio',
    'categoriaprodotto/dettaglio',
    'unitamisura/dettaglio',
    'entepatrocinante/dettaglio',
    'faq/dettaglio',
    'ordine/dettaglio',
    'taglia/dettaglio',
    'catalogo/ricerca', 'catalogo/nuovo', 'catalogo/dettaglio',
    'manuale/upload', 'manuale/downlaod'
  ]
};

export const URLALLOWED = {
  maps: 'maps.googleapis.com'
};

export const INFO_IMMAGINI_NEGOZIO = {
  aspectRatio: '16/9',
  resizeToWidth: 300,
  resizeToHeight: 200
}

export const INFO_IMMAGINI_PRODOTTO = {
  aspectRatio: '4/3',
  resizeToWidth: 300,
  resizeToHeight: 200
}

export const INFO_IMMAGINI_CATEGORIA_COMMERCIALE = {
  aspectRatio: '22/10',
  resizeToWidth: 300,
  resizeToHeight: 200
}

export const INFO_IMMAGINI_ENTE_PATROCINANTE = {
  aspectRatio: '1/1',
  resizeToWidth: 300,
  resizeToHeight: 200
}



