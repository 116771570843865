import { Injectable, TemplateRef } from '@angular/core';
import { BsModalService, BsModalRef, ModalOptions } from 'ngx-bootstrap/modal';
import { Observable, Subscriber } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { Option, ConfirmDialogComponent } from '../../components/confirm-dialog/confirm-dialog.component';

@Injectable({
  providedIn: 'root'
})
export class MessageService {
  bsModalRef: BsModalRef;
  messages: Array<string>;

  constructor(private bsModalService: BsModalService,
    private translate: TranslateService) { }

  confirm(message: string | Array<string>, title: string = 'Conferma', options?: Option[]): Observable<Option> {
    if (!options) {
      options = [new Option('si', 'Si'), new Option('no', 'No')];
    }
    const messages: Array<string> = message instanceof Array ? message : [ message ];
    this.translate.get([...messages, title, ...options.map(option => option.name)])
    .subscribe(res => {
      title = res[title];
      this.messages = messages.map(val => res[val]);
      options.forEach(option => option.name = res[option.name]);
      const initialState = {
        title: title,
        messages: messages,
        options: options,
        answer: new Option('', '')
      };
      this.bsModalRef = this.bsModalService.show(ConfirmDialogComponent, { initialState });
    });

    return new Observable<Option>(this.getConfirmSubscriber());
  }

  alert(message: string | Array<string>, title: string = 'Avviso', options?: Option[]): Observable<Option> {
    if (!options) {
      options = [new Option('ok', 'Ok')];
    }
    const messages: Array<string> = message instanceof Array ? message : [ message ];
    this.translate.get([...messages, title, ...options.map(option => option.name)])
    .subscribe(res => {
      title = res[title];
      this.messages = messages.map(val => res[val]);
      options.forEach(option => option.name = res[option.name]);
      const initialState = {
        title: title,
        messages: messages,
        options: options,
        answer: new Option('', '')
      };
      this.bsModalRef = this.bsModalService.show(ConfirmDialogComponent, { initialState });
    });
    return new Observable<Option>(this.getConfirmSubscriber());
  }

  private getConfirmSubscriber(): (this: Observable<Option>, subscriber: Subscriber<Option>) => void {
    return (observer) => {
      const subscription = this.bsModalService.onHidden.subscribe(() => {
        observer.next(this.bsModalRef.content.answer);
        observer.complete();
      });

      return {
        unsubscribe() {
          subscription.unsubscribe();
        }
      };
    };
  }

  public show(content: string | TemplateRef<any> | any, config?: ModalOptions): BsModalRef {
    return this.bsModalService.show(content, config);
  }

  public closeAllModals() {
    for (let i = 1; i <= this.bsModalService.getModalsCount(); i++) {
      this.bsModalService.hide(i);
    }
  }

}
