import { Injectable } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';

@Injectable({
    providedIn: 'root'
})
export class SpinnerService {

    private loadingNumber: number;

    constructor(private spinnerService: NgxSpinnerService) {
        this.loadingNumber = 0;
    }

    public increaseLoading() {
        if (this.loadingNumber === 0) {
            this.spinnerService.show();
        }
        this.loadingNumber++;
    }

    public decreaseLoading() {
        if (this.loadingNumber > 0) {
            this.loadingNumber--;
            if (this.loadingNumber === 0) {
                this.spinnerService.hide();
            }
        } else {
            // throw Error('Errore interno del contatore dello spinner.');
        }
    }

    public resetLoading() {
        this.loadingNumber = 0;
        this.spinnerService.hide();
    }
}
