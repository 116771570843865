import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BsLocaleService, BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
import { itLocale, enGbLocale, esLocale, frLocale } from 'ngx-bootstrap/locale';
import { formatDate } from '@angular/common';
import { defineLocale } from 'ngx-bootstrap/chronos';
import { Observable, BehaviorSubject, pipe } from 'rxjs';
import { publishReplay, refCount } from 'rxjs/operators';
import { ILocale } from '../../models/ILocale';

export const LOCALEKEY = 'easyadt_locale';

/**
 * @constant { Locale[] } SUPPORTEDLOCALES lista di lingue supportate dall'applicazione, per ciascuna lingua è
 * specificato il css di icone prese dalla libreria https://www.npmjs.com/package/flag-icon-css.
 * @default
 */
export const SUPPORTEDLOCALES: ILocale[] = [
  {
    id: 'it',
    description: 'Italiano',
    menuLabel: 'Lingua',
    icon: 'flag-icon flag-icon-it'
  },
  {
    id: 'en',
    description: 'English',
    menuLabel: 'Language',
    icon: 'flag-icon flag-icon-gb'
  },
  /*{
    id: 'fr',
    description: 'Français',
    menuLabel: 'Langue'
  },
  {
    id: 'es',
    description: 'Español',
    menuLabel: 'Idioma'
  }*/
];

/**
 * @description service che gestisce l'internazionalizzazione dell'applicazione.
 *
 * @todo implementare l'import dinamico delle lingue, in base ai nomi dei files in  ..assets/i18n.
 * potrebbe richiedere l'upgrade alla versione 8.x.x di Angular, per sfruttare questa funzionalità del compiler.
 */
@Injectable({
  providedIn: 'root'
})
export class LocaleService {

  private _bsConfig: BehaviorSubject<Partial<BsDatepickerConfig>>;
  private bsConfig: Observable<Partial<BsDatepickerConfig>>;
  private defaultLocale: string;
  /**
   * 1-Viene creato un Hot Observable che emette la configurazione da utilizzare per il datepicker di ngxBootstrap.
   * 2-Al translateService sono aggiunti gli id delle lingue in SUPPORTEDLOCALES come lingue disponibili
   * per la traduzione.
   * 3-Al translateService è settata come lingua di default l'id della lingua utilizzata dal browser se presente tra le lingue supportate
   * in SUPPORTEDLOCALES, altrimenti come lingua di default è scelto l'id della prima lingua presente in SUPPORTEDLOCALES.
   * @param { TranslateService } translateService per la gestione dell'internazionalizzazione dell'applicazioine, sfrutta i files .json nel
   * folder ..assets/i18n.
   *
   * @param { BsLocaleService } bsLocaleService per la gestione dell'internazionalizzazione di ngxBootstrap.
   */
  constructor(
    private translateService: TranslateService,
    private bsLocaleService: BsLocaleService) {

    this._bsConfig = new BehaviorSubject<Partial<BsDatepickerConfig>>({});
    this.bsConfig = this._bsConfig.asObservable().pipe(
      publishReplay(1),
      refCount());

    const browserLocale = this.translateService.getBrowserCultureLang();
    const locales: string[] = [];
    this.supportedLocales.forEach((locale) => locales.push(locale.id));
    this.defaultLocale = locales[0];
    this.translateService.addLangs(locales);
    this.translateService.setDefaultLang(locales.includes(browserLocale) ? browserLocale : locales[0]);
    this.defineNgxBootstrapLocales();
    this.use(this.localStorageLang);
  }

  /**
   * L'id del locale passato come parametro è:
   * 1-salvato all'interno del localStorage dell'app.
   * 2-utilizzato per cambiare la lingua di translateService e bsLocaleService.
   * Viene emmessa una nuova configurazione per il datapicker di ngxBootstrap con la lingua relativa all' id del locale passato
   * come parametro.
   * @param { string } locale id della lingua
   *
   * @returns { void }
   */
  public use(locale: string): void {
    localStorage.setItem(LOCALEKEY, locale);
    this.translateService.use(locale);
    this.bsLocaleService.use(locale);
    this._bsConfig.next(Object.assign({},
      {
        locale: this.localStorageLang,
        showWeekNumbers: true,
      }
    ));
  }

  /**
   * Il metodo restituisce l'id del locale salvato nel localStorage.
   *
   * @returns { string } l'id del locale salvato nel localStorage
   */
  public get localStorageLang(): string {
    return localStorage.getItem(LOCALEKEY) ? localStorage.getItem(LOCALEKEY) : this.defaultLocale;
  }

  /**
   * Restituisce un Observable che permette di sottoscriversi alle modifiche della configurazione del datepicker,
   * emesse al cambiamento della lingua
   * @example <input type="text" bsDatepicker [bsConfig]="localeService.bsConfig$ | async">
   * @returns { Observable<Partial<BsDatepickerConfig>> } observable per la configurazione del datepicker di ngxBootstrap
   */
  public get bsConfig$(): Observable<Partial<BsDatepickerConfig>> {
    return this.bsConfig;
  }

  /**
   * Restituisce una lista di oggetti con gli id delle lingue supportate dell'applicazione, una descrizione della lingua e
   * una label con la traduzione della parola 'Lingua' nel linguaggio di riferimento.
   *
   * @returns { Locale[] } lista di lingue supportate dall'applicazione.
   */
  public get supportedLocales(): ILocale[] {
    return SUPPORTEDLOCALES;
  }

  /**
   * Carica i moduli delle lingue disponibili per ngxBootstrap e angular.
   */
  private defineNgxBootstrapLocales() {
    defineLocale('it', itLocale);
    defineLocale('en', enGbLocale);
    defineLocale('es', esLocale);
    defineLocale('fr', frLocale);
  }

  /**
   * Formatta la data in una stringa
   *
   * @param { Date } date data da tradurre
   * @param { string } format formato di traduzione, se non è passato come parametro la data sarà in formato 'dd-MM-yyyy'
   */
  public formatDate(date: Date, format: string = 'DD/MM/YYYY', locale?: string): string {
    if (!date) {
      return '';
    }
    return formatDate(date, format, locale);
  }

  /**
   * Converte la data in una stringa contente l'ora
   *
   * @param { Date } date data da tradurre
   * @param { string } format formato di traduzione, se non è passato come parametro la data sarà in formato 'HH:mm'
   */
  public dateToHour(date: Date, format: string = 'HH:mm', locale?: string): string {
    if (!date) {
      return '';
    }
    return formatDate(date, format, locale);
  }

  public timeStringToDate(timeString: string): Date {
    if (!timeString || !timeString.match(new RegExp('^(0[0-9]|1[0-9]|2[0-3]|[0-9])[0-5][0-9]$'))) {
      return;
    }
    const d = new Date();
    d.setHours(+(timeString[0] + timeString[1]));
    d.setMinutes(+(timeString[2] + timeString[3]));
    d.setSeconds(0, 0);
    return d;
  }

  public getAge(birthDate: Date): number {
    if (typeof birthDate === 'string') {
      birthDate = new Date(birthDate);
    }
    const today = new Date();
    let age = today.getFullYear() - birthDate.getFullYear();
    const month = today.getMonth() - birthDate.getMonth();
    if (month < 0 || (month === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }
    return age;
  }
}
