import { Injectable, SecurityContext } from '@angular/core';
import { ToasterService, Toast, BodyOutputType } from 'angular2-toaster';
import { TranslateService } from '@ngx-translate/core';
import { DomSanitizer } from '@angular/platform-browser';
import { RisultatoDTO } from '../../models';

@Injectable({
  providedIn: 'root'
})
export class ToastService {

  constructor(private toasterService: ToasterService,
              private translate: TranslateService,
              private sanitizer: DomSanitizer) { }

  public popResultToast(result: RisultatoDTO<any> | Array<RisultatoDTO<any>>): void {
    if (result instanceof Array) {
      result.forEach(res => {
        this.popResultToast(res);
      });
    } else {
      if (result.info) {
        this.popInfoToast(result.info);
      }
      if (result.warnings) {
        this.popWarnigToast(result.warnings);
      }
      if (!result.success) {
        if (result.descrizione) {
            this.popErrorToast(result.descrizione);
          } else {
            this.popErrorToast(result.descrizione);
        }
      }
    }
  }
  private popTranslateToast(type: string, body: string, title: string, interpolateParams?: Object): void {
    this.translate
      .get([body, title], interpolateParams)
      .subscribe((res: string) => {
        const toast: Toast = {
          type: type,
          title: this.sanitizer.sanitize(SecurityContext.HTML, res[body]),
          body: res[title],
          bodyOutputType: BodyOutputType.TrustedHtml
        };
        this.toasterService.pop(toast);
      });
  }

  //public popSuccessToast(body: string, title: string = 'form.toast.success'): void {
    public popSuccessToast(body: string, title: string = ''): void {
      this.popTranslateToast('success', title, body);
    }
  
    public popSuccessToastWithTimer(body: string, title: string = 'form.toast.success', timer: number): void {
      this.popTranslateToast('success', title, body, timer);
    }
  // public popErrorToast(body: string, title: string = 'form.toast.error'): void {
    public popErrorToast(body: string, title: string = ''): void {
      this.popTranslateToast('error', title, body);
    }
  
    public popErrorToastWithTimer(body: string, title: string = 'form.toast.error', timer: number): void {
      this.popTranslateToast('error', title, body, timer);
    }
  //    public popWarnigToast(body: string, title: string = 'form.toast.warning'): void {
    public popWarnigToast(body: string, title: string = ''): void {
      this.popTranslateToast('warning', title, body);
    }
  
    public popWarnigToastWithTimer(body: string, title: string = 'form.toast.warning', timer: number): void {
      this.popTranslateToast('error', title, body, timer);
    }
    //public popInfoToast(body: string, title: string = 'form.toast.info'): void {
    public popInfoToast(body: string, title: string = ''): void {
      this.popTranslateToast('info', title, body);
    }
  
    public popInfoToastWithTimer(body: string, title: string = 'form.toast.info', timer: number): void {
      this.popTranslateToast('error', title, body, timer);
    }
  }
  