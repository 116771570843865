import { required, email } from '@rxweb/reactive-form-validators';
import { FormGroup } from '@angular/forms';

export class PasswordDimenticataFormModel {

    form: FormGroup;

    @required()
    t_email: string;

    constructor() {
        this.t_email = null;
    }
    
}