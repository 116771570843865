import { NgModule } from '@angular/core';
import { CommonModule, LocationStrategy, HashLocationStrategy } from '@angular/common';
import { P500Component, P404Component, LoginComponent, PasswordDimenticataComponent, DefaultLayoutComponent } from './components';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { RxReactiveFormsModule } from '@rxweb/reactive-form-validators';
import { AppAsideModule, AppBreadcrumbModule, AppFooterModule, AppHeaderModule, AppSidebarModule } from '@coreui/angular';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from '../app.routing';
import { NgIdleKeepaliveModule } from '@ng-idle/keepalive';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { ToasterModule } from 'angular2-toaster';
import { Ng2CacheModule } from 'ng2-cache';
import { PerfectScrollbarModule, PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { NgxSpinnerModule } from 'ngx-spinner';
import { PaginationModule } from 'ngx-bootstrap/pagination';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { TranslateModule } from '@ngx-translate/core';
import { MessageService, ToastService, IdleService, RouterExtService } from './services';
import { ChartsModule } from 'ng2-charts';
import { translateModuleConfig } from './utility/configs';
import { AccordionModule } from 'ngx-bootstrap/accordion';
import { TimepickerModule } from 'ngx-bootstrap/timepicker';
import { BsModalService, ModalModule } from 'ngx-bootstrap/modal';
import { httpLoaderInterceptorProvider, httpInterceptorProviders } from './utility/factories';
import { ImageCropperModule } from 'ngx-image-cropper';

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true
};

const APP_CONTAINERS = [
  DefaultLayoutComponent
];

@NgModule({
  declarations: [
    ...APP_CONTAINERS,
    P404Component,
    P500Component,
    LoginComponent,
    PasswordDimenticataComponent
  ],
  imports: [
    CommonModule,
    NgIdleKeepaliveModule.forRoot(),
    BrowserAnimationsModule,
    AppRoutingModule,
    AppAsideModule,
    AppBreadcrumbModule.forRoot(),
    AppFooterModule,
    AppHeaderModule,
    AppSidebarModule,
    HttpClientModule,
    BsDatepickerModule.forRoot(),
    CollapseModule.forRoot(),
    ToasterModule.forRoot(),
    NgxSpinnerModule,
    Ng2CacheModule,
    PerfectScrollbarModule,
    FormsModule,
    RxReactiveFormsModule,
    ReactiveFormsModule,
    PaginationModule.forRoot(),
    BsDropdownModule.forRoot(),
    TabsModule.forRoot(),
    TooltipModule.forRoot(),
    TranslateModule.forRoot(translateModuleConfig),
    ChartsModule,
    AccordionModule.forRoot(),
    TimepickerModule.forRoot(),
    FormsModule,
    RxReactiveFormsModule,
    ReactiveFormsModule,
    ModalModule.forRoot(),
    ImageCropperModule
  ],
  providers: [
    HttpClient,
    MessageService,
    ToastService,
    IdleService,
    BsModalService,
    RouterExtService,
    {
      provide: LocationStrategy,
      useClass: HashLocationStrategy
    },
    httpLoaderInterceptorProvider,
    httpInterceptorProviders
  ],
})
export class CoreModule {
  constructor(private routerExtService: RouterExtService) { }
}
