// se il deploy è sulla macchina di sviluppo(Windows) -> production: false, position_formato: 1 
export const environment = {
  production: false,
  apiBaseUrl: 'https://fenice.svil.itsvil.it/prophet/',
 /*  apiBaseUrl: '/prophet/', */ 
  loginPage: '/login',
  logoutPage: '/login',
  projectName: 'Cishoppo',
  projectYear: '2020',
  footerLink: 'https://www.itsvil.it',
  applicationUsername: 'cishoppoweb',
  applicationPassword: 'cishoppoweb',
  defaultLocale: 'it',
  translationsPrefix: '/assets/i18n/',
  translationsSuffix: '.json',
  translationsListConfig: '/assets/i18n/config.json',
  apiKeyGM: 'AIzaSyCFwvCUj_IJeIdGSLGhkS0Jt9yhTpThffY',
  minuti_inattivita: 30,
  position_formato: 1
};

export const idle = {
  idletimepercentage: 0.8,
  keepaliveinterval: 1,
  idletimefixed: 90
};

export const http_headers = {
  authorization: 'Authorization'
};
