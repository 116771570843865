import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Observable, BehaviorSubject, throwError, EMPTY } from 'rxjs';
import { tap, switchMap, filter, take, catchError } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from '../../../environments/environment';
import { ToastService } from '../services/toast/toast.service';
import { AuthService } from '../services/auth/auth.service';
import { RisultatoDTO, TokenResponse } from '../models';
import { URLALLOWED } from '../utility/global-constant';

export const http_header = 'Authorization';

@Injectable({
  providedIn: 'root'
})
export class HttpTokenInterceptor implements HttpInterceptor {
  sessione_scaduta: boolean;
  private isRefreshing = false;
  private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  constructor(
    private authService: AuthService,
    private router: Router,
    private toastService: ToastService) { }

  /* Se non si lavora in locale e non si usa la regola di ningx */
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
      if (request.headers.has(http_header) || this.checkUrlNotNeedToken(request.url)) {
        return next.handle(request);
      } else {
        let token = this.authService.getToken();
        if (token) {
          return next.handle(this.addAuthenticationToken(request, token)).pipe(
            tap((event: HttpEvent<any>) => {
              if (event instanceof HttpResponse) {
                if (token) {
                  this.checkExpireDateToken();
                } else {
                  this.router.navigate([environment.loginPage]);
                }
              }
              return event;
            }),
            catchError(error => {
              if (error instanceof HttpErrorResponse && error.status === 401) {
                return this.handle401Error(request, next);
              } else {
                this.toastService.popErrorToast('Il servizio non è al momento disponibile');
                return throwError(error);
              }
            }));
        } else {
          this.router.navigate([environment.loginPage]);
          return EMPTY;
        }
      }
    } 

  /* Se si lavora in locale e si usa la regola con nginx */
/* intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      tap((event: HttpEvent<any>) => {
        if (event instanceof HttpResponse) {
          let token = this.authService.getToken();
          if (token) {
            this.checkExpireDateToken();
          } else {
            this.router.navigate([environment.loginPage]);
          }
        }
        return event;
      }),
      catchError(error => {
        if (error instanceof HttpErrorResponse && error.status === 401) {
          return this.handle401Error(request, next);
        } else {
          this.toastService.popErrorToast('Il servizio non è al momento disponibile');
          return throwError(error);
        }
      }));
  } */

  handle401Error(request: HttpRequest<any>, next: HttpHandler) {
    if (!this.isRefreshing) {
      this.isRefreshing = true;
      this.refreshTokenSubject.next(null);
      return this.authService.refreshToken().pipe(
        switchMap((result: RisultatoDTO<TokenResponse>) => {
          this.isRefreshing = false;
          this.refreshTokenSubject.next(result.data.access_token);
          return next.handle(this.addAuthenticationToken(request, result.data.access_token));
        }));
    } else {
      return this.refreshTokenSubject.pipe(
        filter(token => token != null),
        take(1),
        switchMap(token => {
          return next.handle(this.addAuthenticationToken(request, token));
        }));
    }
  }

  addAuthenticationToken(request: HttpRequest<any>, token: string): HttpRequest<any> {
    return request.clone({
      headers: request.headers.set(http_header, 'Bearer ' + token)
    });
  }

  checkExpireDateToken() {
    let data_corrente = new Date();
    let expires_date = new Date(this.authService.getExpiresToken());
    if (expires_date.getTime() < data_corrente.getTime()) {
      return;
      //GENERAZIONE TOKEN 1 minuti PRIMA
    } else if ((expires_date.getTime() - 60000) < data_corrente.getTime()) {
      this.generateToken();
    } else {
      return;
    }
  }

  generateToken() {
    this.authService.generateToken().subscribe(
      (result) => {
        if (result.code >= 200 && result.code < 300 && result.success) {
          this.authService.storeToken(result.data.access_token);
          this.authService.storeExpiresToken(result.data.expires_at);
        } else {
          this.router.navigate([environment.loginPage]);
        }
      },
      (error) => {
        console.log('error');
        this.router.navigate([environment.loginPage]);
      }
    );
  }

  checkUrlNotNeedToken(url: string): boolean {
    //SE AUMENTANO GLI URL USARE UN FOR
    if(url.includes(URLALLOWED.maps)) {
      return true;
    } else {
      return false;
    }
  }

}